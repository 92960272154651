/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ComponentRef, createElement, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ActionTypes from '../../components/form/ActionTypes';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import PermissionsModal from '../../components/ownership/PermissionsModal';
import StaticBreadCrumb from '../../components/shared/breadcumb/StaticBreadCrumb';
import DataTable from '../../components/shared/data-grid/DataTable';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import EditIcon from '../../components/shared/icon/EditIcon';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import PlusIcon from '../../components/shared/icon/PlusIcon';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import usePermissions from '../../hooks/permissions/usePermissions';
import useResourcePermissions from '../../hooks/permissions/useResourcePermissions';
import { FormConfig } from '../../models/Form';
import { RecordColumnData } from '../../models/Record';
import { Roles } from '../../models/Role';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import TemplateFormService from '../../services/TemplateFormService';
import LanguageUtils from '../../utils/LanguageUtils';
import ObjectUtils from '../../utils/ObjectUtils';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import NewTaskModal from '../../components/tasks/NewTaskModal';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import { InputStyle } from '../../components/shared/form-control/Input';
import ContextMenu, { ContextMenuItem } from '../../components/shared/ContextMenu';
import UsersIcon from '../../components/shared/icon/UsersIcon';
import { SelectItemContextProvider } from '../../contexts/select-items/SelectItemsContext';
import ExportIcon from '../../components/shared/icon/ExportIcon';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';
import { ClientTemplateFormUser } from '../../models/ClientTemplateFormUser';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';

const Resource: FC = () => {
  const { t } = useTranslation('resources');
  const params = useParams<{ resourceId: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const [isLoading, setIsLoading] = useState(true);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const clientTemplateFormService = useMemo(() => (currentClient ? new ClientTemplateFormService(currentClient?.id) : null), [currentClient]);

  const [showPermisionsModal, setShowPermisionsModal] = useState(false);
  const [showNewRecordModal, setShowNewRecordModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState<FormConfig | null>(null);
  const hasPermission = usePermissions();
  const { users, setUsers, canMaintainRecords, canMaintainResources, isUserArchitect } = useResourcePermissions(selectedResource);

  const currentUser = useRecoilValue(currentUserAtom);
  const exportProvider = useRef<ComponentRef<typeof SelectItemContextProvider>>(null);
  const { featureFlags } = useFeatureFlags();

  useEffect(
    function triggerModalFromUrl() {
      const templateId = searchParams.get('import');
      if (!templateId) {
        return;
      }

      // Note, not removing param from url in this component, since the new task modal
      // needs to set its internal state still to show the import mode
      setShowNewRecordModal(true);
    },
    [searchParams],
  );

  const [isLocalResource, importJobFilterId] = useMemo(() => {
    return [searchParams.get('isLocal'), searchParams.get('importId')];
  }, [searchParams]);

  const templateFormService = useMemo(() => {
    if (currentClient && isLocalResource) {
      return clientTemplateFormService;
    }
    return TemplateFormService;
  }, [clientTemplateFormService, currentClient, isLocalResource]);

  useEffect(
    function getResource() {
      if (params?.resourceId) {
        setIsLoading(true);
        templateFormService?.getFormTemplate(params.resourceId).then((res) => {
          setSelectedResource(res.data);
          setIsLoading(false);
        });
      }
    },
    [currentClient, importJobFilterId, params.resourceId, templateFormService],
  );

  const columns = useMemo(() => {
    if (selectedResource) {
      return [
        {
          header: t('title-column'),
          accessorKey: 'subTitle',
          id: 'subTitle',
          enableSorting: true,
        },
        ...selectedResource.sections
          .filter((x) => !x.isDraft)
          .flatMap((x) => x.actions || [])
          .filter((x) => x.shownInResourceOverview)
          .map((x) => {
            const translatedData = LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(x));
            return {
              header: translatedData?.question || translatedData?.title,
              accessorKey: x.id,
              id: x.id,
              minSize: 80,
              cell: ({ getValue }: CellContext<any, unknown>) => {
                const recordColumnData = getValue<RecordColumnData>();
                if (recordColumnData) {
                  const componentInfo = ActionTypes[recordColumnData.type];
                  const component = componentInfo?.previewAction;

                  if (component) {
                    return (
                      <div
                        className={`truncate ${
                          !componentInfo.resourceAlignment || componentInfo.resourceAlignment === 'left' ? 'text-left' : 'text-right'
                        }`}
                      >
                        {createElement(component, {
                          id: x.id,
                          data: translatedData,
                          response: recordColumnData.answerData,
                          answerOnly: true,
                          required: false,
                        })}
                      </div>
                    );
                  }
                }
                return '-';
              },
            } as ColumnDef<any>;
          }),
      ];
    }
    return [];
  }, [selectedResource, t]);

  const resourceTitle = useMemo(() => {
    if (selectedResource) {
      return LanguageUtils.getTranslation('title', selectedResource?.translations, currentUser?.language);
    }
    return '';
  }, [selectedResource, currentUser?.language]);

  const addOrUpdateUsers = (changedUsers: ClientTemplateFormUser[]) => {
    if (selectedResource && clientTemplateFormService) {
      setUsers(changedUsers);
      return clientTemplateFormService.addOrUpdateUsers(selectedResource?.id, changedUsers);
    }
    return Promise.resolve();
  };

  const contextItems = useMemo<ContextMenuItem[]>(() => {
    return [
      {
        title: t('buttons.manage-members'),
        icon: <UsersIcon className="h-5 w-5" />,
        onClick: () => {
          setShowPermisionsModal(true);
        },
        hide: !hasPermission(Roles.TeamMember),
      },
      {
        title: t('buttons.export'),
        icon: <ExportIcon className="h-5 w-5" />,
        onClick: () => {
          exportProvider.current?.selectAll(true);
          exportProvider.current?.setShowDataExportModal(true, 'excel');
        },
        hide: !hasPermission(Roles.TeamMember) || !featureFlags.dataExport,
      },
      {
        title: t('buttons.edit'),
        icon: <EditIcon className="h-5 w-5" />,
        onClick: () =>
          selectedResource?.isOwnedByClient
            ? navigate(`/clients/${currentClient?.id}/resource-builder/${selectedResource?.id}/${selectedResource?.version}`)
            : navigate(`/builder/${currentTenantId}/resource-builder/${selectedResource?.id}/${selectedResource?.version}`),
        hide: !((canMaintainResources && selectedResource?.isOwnedByClient) || (!selectedResource?.isOwnedByClient && isUserArchitect)),
      },
    ];
  }, [
    canMaintainResources,
    currentClient?.id,
    currentTenantId,
    featureFlags.dataExport,
    hasPermission,
    isUserArchitect,
    navigate,
    selectedResource?.id,
    selectedResource?.isOwnedByClient,
    selectedResource?.version,
    t,
  ]);

  const [recordSearchPhrase, setRecordSearchPhrase] = useState('');

  const rowLinkProvider = useCallback(
    (id: string) => (selectedResource?.id && currentClient?.id ? `/clients/${currentClient.id}/resources/${selectedResource.id}/${id}` : ''),
    [currentClient?.id, selectedResource?.id],
  );

  const extraQueryFilters = useMemo(() => ({ importId: importJobFilterId }), [importJobFilterId]);

  const queryFn = useCallback(
    (filter: any) => {
      exportProvider.current?.setFilter({ ...filter, templateIds: [selectedResource?.id] });
      return new ClientTemplateFormService(currentClient?.id ?? '').getRecordsWithAnswers(selectedResource?.id ?? '', filter);
    },
    [currentClient?.id, selectedResource?.id],
  );

  return (
    <>
      <PageLoader loading={isLoading}>
        <div className="bg-background-1 flex h-full flex-grow flex-col">
          <TopNavPortal>
            <StaticBreadCrumb
              returnPath={`/clients/${currentClient?.id}/resources`}
              breadCrumbs={[{ name: t('heading'), path: `/clients/${currentClient?.id}/resources` }]}
              currentStepName={resourceTitle}
            />
          </TopNavPortal>

          <div className="page-content flex h-full flex-col overflow-y-auto" ref={pageContentRef}>
            <div className="mb-6 flex items-center justify-end px-6 pt-6">
              <div className="flex flex-1 flex-row items-center">
                <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
                  {selectedResource && <>{resourceTitle}</>}
                </Heading>
              </div>

              <div className="piped-items flex items-center justify-end gap-4">
                <div className="w-72 flex-shrink-0">
                  <SearchInput
                    style={InputStyle.MINIMAL}
                    placeholder={t('search')}
                    value={recordSearchPhrase}
                    onChange={(e) => {
                      setRecordSearchPhrase(e.target.value);
                    }}
                    data-cy="search-resource"
                  />
                </div>
                <div className="ml-4 flex flex-shrink-0 items-center gap-2">
                  <Button onClick={() => setShowNewRecordModal(true)} type={ButtonType.PRIMARY} size={ButtonSize.S} data-cy="create-new-record">
                    <Button.Slot name="Icon">
                      <PlusIcon className="w-3" />
                    </Button.Slot>
                    {t('buttons.create-record')}
                  </Button>
                  {contextItems.some((x) => !x.hide) && <ContextMenu items={contextItems} />}
                </div>
              </div>
            </div>
            <div className="h-full w-full bg-white p-5">
              {!!currentClient && !!columns?.length && selectedResource && (
                <SelectItemContextProvider ref={exportProvider}>
                  <DataTable
                    dataId={selectedResource.id}
                    columns={columns}
                    rowLinkProvider={rowLinkProvider}
                    extraQueryFilters={extraQueryFilters}
                    searchTerm={recordSearchPhrase}
                    queryFn={queryFn}
                    enableMultiSelect={hasPermission(Roles.TeamMember) && featureFlags.dataExport}
                    title={resourceTitle}
                  >
                    <DataTable.Slot name="Empty">
                      <div data-cy="list-empty" className="flex h-full w-full items-center justify-center">
                        <div className="text-center">
                          <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                          <Heading size={HeadingSize.H3} className="my-4">
                            {t('record-list.empty.heading')}
                          </Heading>
                          <div className="text-dpm-20 pb-5">{t('record-list.empty.sub-heading')}</div>
                          {canMaintainRecords && (
                            <Button
                              onClick={() => setShowNewRecordModal(true)}
                              type={ButtonType.PRIMARY}
                              size={ButtonSize.S}
                              data-cy="create-new-record"
                            >
                              <Button.Slot name="Icon">
                                <PlusIcon className="w-3" />
                              </Button.Slot>
                              {t('buttons.create-record')}
                            </Button>
                          )}
                        </div>
                      </div>
                    </DataTable.Slot>
                  </DataTable>
                </SelectItemContextProvider>
              )}
            </div>
          </div>
        </div>
        <NewTaskModal
          open={showNewRecordModal}
          onClose={() => setShowNewRecordModal(false)}
          modalHeader={t('modals.add-record.heading')}
          templateId={selectedResource?.id || ''}
          templateFormName={!selectedResource ? '' : LanguageUtils.getTranslation('title', selectedResource.translations)}
          predefinedSubtitle={
            !selectedResource
              ? undefined
              : interpolateActionData(
                  LanguageUtils.getTranslation('predefinedTitle', selectedResource.translations),
                  selectedResource.placeholders || [],
                )
          }
          templateClient={selectedResource?.clientId || null}
          onCreated={(cf) => navigate(`/clients/${currentClient?.id}/resources/${selectedResource?.id}/${cf.id}`)}
          enableImporting
          onRetry={() => setShowNewRecordModal(true)}
        />

        <PermissionsModal
          requiresApproval={false}
          requiresValidation={false}
          excludeOwnerRole={true}
          users={users}
          open={showPermisionsModal}
          onClose={() => setShowPermisionsModal(false)}
          onAddorUpdate={addOrUpdateUsers}
          heading={t('modals.permissions.heading')}
          canInvite={false}
        />
      </PageLoader>
    </>
  );
};

export default Resource;
