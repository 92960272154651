/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, PaginationState, SortingState, ColumnDef, Header, Row } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { Option } from '../../Option';
import DropdownSelect, { DropdownSize } from '../form-control/DropdownSelect';
import { ArrowIcon, ArrowType } from '../icon/ArrowIcon';
import { Trans, useTranslation } from 'react-i18next';
import { ApiResponse } from '../../../models/ApiResponse';
import InfoIcon from '../icon/InfoIcon';
import { Heading, HeadingSize } from '../text/Heading';
import useSlot from '../../../hooks/useSlots';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import { InputStyle } from '../form-control/Input';
import { SearchInput } from '../form-control/SearchInput';
import Button, { ButtonSize, ButtonType } from '../form-control/Button';
import PlusIcon from '../icon/PlusIcon';
import useDebounce from '../../../hooks/useDebounce';
import Loader from '../Loader';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import { SortDirection, sortExpression } from '../../../utils/ListUtils';
import Tooltip from '../Tooltip';
import { Link } from 'react-router-dom';
import PageLoader from '../page-loader/PageLoader';
import { EventSystem } from '../../../events/EventSystem';
import Checkbox from '../form-control/Checkbox';
import { useDebugHotkey } from '../../../hooks/useDebugHotkey';
import { useItemSelection } from '../../../contexts/select-items/SelectItemsContext';
import { DataJobSourceType } from '../../../models/DataImport';

const SIDEBAR_WIDTH = 72;
const TABLE_SIDE_PADDING = 20;
const SELECT_COLUMN_WIDTH = 66;
const SELECT_COLUMN_ID = 'select';

type TableProps = {
  dataId: string;
  columns: ColumnDef<any>[];
  queryFn: (filter: any) => Promise<ApiResponse<any>>;
  onRowClick?: (id: string) => void;
  rowLinkProvider?: (id: string) => string;
  onAddClick?: () => void;
  addButtonLabel?: string;
  searchTerm?: string;
  extraQueryFilters?: Record<string, unknown>;
  enableMultiSelect?: boolean;
  title: string;
};

const DataTable = withSlot<TableProps, SlotDefinitions<['Empty']>>((props) => {
  const {
    dataId,
    columns,
    queryFn,
    onRowClick,
    onAddClick,
    rowLinkProvider,
    addButtonLabel,
    extraQueryFilters,
    searchTerm,
    enableMultiSelect: enableExport,
    title,
  } = props;

  const { t } = useTranslation('data-table');
  const [recordSearchPhrase, setRecordSearchPhrase] = useState(searchTerm || '');
  const debouncedSearchTerm = useDebounce(recordSearchPhrase, 500);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (searchTerm !== undefined) {
      setRecordSearchPhrase(searchTerm);
    }
  }, [searchTerm]);

  const initialColumnSort: SortingState = [{ id: columns.find((x) => x.enableSorting)?.id || '', desc: true }].filter((x) => x.id);
  const getSortExpression = useCallback((columns: SortingState) => {
    return columns
      .map((column) => {
        const direction = column.desc ? SortDirection.Desc : SortDirection.Asc;
        return sortExpression(column.id, direction);
      })
      .join(',');
  }, []);

  const [sorting, setSorting] = useState<SortingState>(initialColumnSort);
  const [sortBy, setSortBy] = useState(getSortExpression(initialColumnSort));
  const [rowSelection, setRowSelection] = useState({});
  const { selection, mutateSelection, SelectionBarContent, SelectAllButton } = useItemSelection();
  const debug = useDebugHotkey();

  useEffect(() => {
    setSortBy(getSortExpression(sorting));
  }, [getSortExpression, sorting]);

  const fetchDataOptions = {
    pageNumber: pageIndex + 1,
    pageSize,
    sortBy: sortBy || undefined,
    searchTerm: debouncedSearchTerm || undefined,
    ...(extraQueryFilters || {}),
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`data-${dataId}`, fetchDataOptions],
    queryFn: () => queryFn(fetchDataOptions),
  });

  useEffect(() => {
    const handler = () => refetch();
    EventSystem.listen('data-import-done', handler);
    return () => EventSystem.stopListening('data-import-done', handler);
  }, [refetch]);

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
      debouncedSearchTerm,
    }),
    [pageIndex, pageSize, debouncedSearchTerm],
  );

  const pageSizeOptions: Option<string, number>[] = [
    { id: '10', text: '10', value: 10 },
    { id: '20', text: '20', value: 20 },
    { id: '30', text: '30', value: 30 },
    { id: '40', text: '40', value: 40 },
    { id: '50', text: '50', value: 50 },
  ];

  const pageOptions: Option<string, number>[] = useMemo(() => {
    const pages = [];
    const total = data?.totalPages ?? 0;
    for (let i = 0; i < total; i++) {
      const page = i + 1;
      pages.push({ id: `${page}`, text: `${page}`, value: page });
    }
    return pages;
  }, [data]);

  const hasData = useMemo(() => !!data?.totalCount && data?.totalCount > 0, [data?.totalCount]);

  const initialColumnWidth = useMemo(
    () => Math.max(250, (window.innerWidth - SIDEBAR_WIDTH - TABLE_SIDE_PADDING * 2 - (enableExport ? SELECT_COLUMN_WIDTH : 0)) / columns.length),
    [columns.length, enableExport],
  );

  const selectRowColumn: ColumnDef<any> = {
    id: SELECT_COLUMN_ID,
    size: SELECT_COLUMN_WIDTH,
    enableResizing: false,
    header: ({ table }) => SelectAllButton({ visibleItems: table.getRowModel().rows.map((x) => x.original) }),
    cell: ({ row }: any) => (
      <div>
        <Checkbox
          {...{
            containerClassName: '!my-0',
            value: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: (checked) => {
              mutateSelection.selectIndividual(row.original, checked);
            },
          }}
        />
      </div>
    ),
  };

  const table = useReactTable({
    data: data?.data ?? defaultData,
    columns: enableExport ? [selectRowColumn, ...columns] : columns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: initialColumnWidth,
    },
    enableColumnResizing: true,
    pageCount: data?.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      rowSelection,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    debugTable: debug,
  });

  useEffect(() => {
    setRowSelection(
      Object.assign(
        {},
        ...table.getRowModel().rows.map((row: Row<any>, i) => {
          return { [i]: selection.isSelected(row.original) };
        }),
      ),
    );
  }, [selection, table]);

  const EmptyElement = useMemo(
    () => (
      <div data-cy="list-empty" className="flex h-full w-full items-center justify-center">
        <div className="text-center">
          <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
          <Heading size={HeadingSize.H3} className="my-4">
            {t('empty.heading')}
          </Heading>
        </div>
      </div>
    ),
    [t],
  );

  const EmptyPlaceholder = useSlot(props, 'Empty', EmptyElement);

  const cellContent = useCallback((header: Header<any, unknown>) => {
    const content = flexRender(header.column.columnDef.header, header.getContext());
    return (
      <Tooltip text={content} truncatedTextMode>
        {(tooltip) => (
          <div className="select-none truncate" {...tooltip}>
            {content}
          </div>
        )}
      </Tooltip>
    );
  }, []);

  const rowsSelectedText = useMemo(() => {
    if (selection.selectedCount > 0 && data?.totalCount) {
      if (selection.selectedCount < table.getState().pagination.pageSize) {
        return t('multi-select.some-records-selected', { amount: selection.selectedCount });
      } else if (selection.selectedCount === table.getState().pagination.pageSize) {
        return (
          <>
            {t('multi-select.all-page-records-selected', { amount: selection.selectedAll ? data?.totalCount : selection.selectedCount })}
            {selection.selectedCount < data?.totalCount && (
              <>
                {!selection.selectedAll && (
                  <Button type={ButtonType.TERTIARY} className="ml-2 !p-0" onClick={() => mutateSelection.selectAll(true)}>
                    {t('multi-select.select-all-button', { amount: data?.totalCount, collection: title })}
                  </Button>
                )}
                {selection.selectedAll && (
                  <Button
                    type={ButtonType.TERTIARY}
                    className="ml-2 !p-0"
                    onClick={() =>
                      mutateSelection.clear(
                        table.getRowModel().rows.map((row: Row<any>) => {
                          return { id: row.original.id };
                        }),
                      )
                    }
                  >
                    {t('multi-select.clear')}
                  </Button>
                )}
              </>
            )}
          </>
        );
      }
    }
    return '';
  }, [selection.selectedCount, selection.selectedAll, data?.totalCount, table, t, title, mutateSelection]);

  return (
    <div>
      {(searchTerm === undefined || onAddClick) && (
        <div className="flex justify-end gap-2 pb-5">
          <div className="w-60">
            {searchTerm === undefined && (
              <SearchInput
                style={InputStyle.MINIMAL}
                placeholder={t('search')}
                value={recordSearchPhrase}
                onChange={(e) => {
                  setRecordSearchPhrase(e.target.value);
                }}
                data-cy="search-resource"
              />
            )}
          </div>

          {onAddClick && (
            <Button onClick={() => onAddClick()} type={ButtonType.PRIMARY} size={ButtonSize.S} data-cy="create-new-record">
              <Button.Slot name="Icon">
                <PlusIcon className="w-3" />
              </Button.Slot>
              {addButtonLabel}
            </Button>
          )}
        </div>
      )}
      <div className="border-gray-5 flex max-w-full flex-col justify-between border-2">
        <div
          {...{
            style: {
              width: table.getTotalSize(),
            },
          }}
          className={`min-w-full max-w-0 overflow-x-auto ${hasData ? 'min-h-128' : ''}`}
        >
          <div className="w-fit">
            {selection.active && (
              <div className="flex items-center px-6 py-3">
                {SelectionBarContent({
                  selectionText: rowsSelectedText,
                  source: { name: title, id: dataId, type: DataJobSourceType.Resource },
                  forceMode: 'excel',
                })}
              </div>
            )}
            {table.getHeaderGroups().map((headerGroup) => (
              <div key={headerGroup.id} className="bg-gray-5 flex w-fit items-center">
                {headerGroup.headers.map((header) => (
                  <div
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      maxWidth: header.getSize(),
                    }}
                    className="relative border-r-2 px-6 py-3 text-left font-normal last:border-r-0"
                  >
                    <div className="flex items-center justify-between">
                      <div
                        onClick={header.column.columnDef.enableSorting ? header.column.getToggleSortingHandler() : undefined}
                        className={`truncate ${header.column.columnDef.enableSorting && header.column.getCanSort() ? 'cursor-pointer' : ''}`}
                      >
                        {header.isPlaceholder ? null : cellContent(header)}
                      </div>
                      {header.column.columnDef.enableSorting && (
                        <div
                          className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                          onClick={header.column.columnDef.enableSorting ? header.column.getToggleSortingHandler() : undefined}
                        >
                          {{
                            asc: <ChevronIcon type={ChevronType.UP} className="h-6 w-6" />,
                            desc: <ChevronIcon type={ChevronType.DOWN} className="h-6 w-6" />,
                          }[header.column.getIsSorted() as string] ?? '-'}
                        </div>
                      )}
                    </div>
                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: header.column.getCanResize() ? `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}` : '',
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="w-fit items-center bg-white">
            {table.getRowModel().rows.map((row) => {
              let Wrapper: typeof Link | keyof JSX.IntrinsicElements = 'div';
              let props: any = {
                onClick: () => onRowClick && onRowClick(row.original.id),
              };
              if (rowLinkProvider) {
                Wrapper = Link;
                props = {
                  to: rowLinkProvider(row.original.id),
                };
              }

              return (
                <Wrapper
                  key={row.id}
                  {...props}
                  className={`${
                    (onRowClick || rowLinkProvider) && 'hover:bg-background-1 cursor-pointer'
                  } border-background-1 flex items-center border-b-2 ${row.getIsSelected() ? 'bg-background-1' : 'bg-white'}`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <div
                      key={cell.id}
                      className="relative whitespace-nowrap px-6 py-4"
                      style={{
                        width: cell.column.getSize(),
                        maxWidth: cell.column.getSize(),
                      }}
                      onClick={(e) => {
                        if (cell.column.id === SELECT_COLUMN_ID) {
                          e.preventDefault();
                          e.stopPropagation();
                          mutateSelection.selectIndividual(row.original, !selection.selectionStatus[row.original.id]);
                        }
                      }}
                    >
                      <Suspense fallback={<PageLoader loading loaderSize={8} />}>
                        <div className="text-dpm-14 truncate text-gray-500">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                      </Suspense>
                    </div>
                  ))}
                </Wrapper>
              );
            })}
          </div>
        </div>
        {hasData && (
          <div className="w-full">
            <div className="bg-gray-5 mb-4 h-px w-full border-0 bg-gray-200" />
            <div className="text-dpm-14 flex justify-between px-4 pb-2">
              <div className="flex items-center gap-3">
                <div className="whitespace-nowrap">{t('paging.show-per-page')}</div>
                <DropdownSelect
                  size={DropdownSize.S}
                  options={pageSizeOptions}
                  value={pageSizeOptions.find((o) => o.value === table.getState().pagination.pageSize)}
                  onChange={(e) => {
                    table.setPageSize(Number(e.value));
                  }}
                  aria-label={t('paging.show-per-page')}
                />
              </div>
              <div className="piped-items flex items-center gap-2">
                <div className="flex items-center gap-3 whitespace-nowrap">
                  <Trans
                    t={t}
                    i18nKey="paging.page-of"
                    values={{ total: table.getPageCount() }}
                    components={{
                      DropdownSelect: (
                        <DropdownSelect
                          size={DropdownSize.S}
                          options={pageOptions}
                          aria-label={t('paging.page-number')}
                          value={pageOptions.find((o) => o.value === table.getState().pagination.pageIndex + 1)}
                          onChange={(e) => {
                            const page = e.value ? Number(e.value) - 1 : 0;
                            table.setPageIndex(page);
                          }}
                        />
                      ),
                    }}
                  />
                </div>
                <div className="flex items-center gap-8 pl-4">
                  <button
                    className="border-none"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    aria-label={t('paging.previous-button')}
                  >
                    <ArrowIcon type={ArrowType.LEFT} className={`w-6 ${!table.getCanPreviousPage() ? 'text-gray-5' : 'text-gray-1'}`} />
                  </button>

                  <button
                    className="border-none"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    aria-label={t('paging.next-button')}
                  >
                    <ArrowIcon type={ArrowType.RIGHT} className={`w-6 ${!table.getCanNextPage() ? 'text-gray-5' : 'text-gray-1'}`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {!hasData && !isLoading && (
          <div className="min-h-128 flex h-full w-full items-center">
            <EmptyPlaceholder />
          </div>
        )}
        {(isLoading || isFetching) && (
          <div className="flex flex-col items-center py-6">
            <Loader centered />
          </div>
        )}
      </div>
    </div>
  );
});

export default DataTable;
